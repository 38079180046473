.header {
  color: white;
  font-weight: lighter;
  font-size: 0.9rem;
  padding-bottom: 0px !important;
}

.header-background {
  background-color: #748b6f;
}

.header-img {
  height: 4rem;
  margin-right: 0px;
}

.profile-pic {
  border-radius: 50%;
  height: 25px;
}

body {
  font-family: "Roboto", sans-serif !important;
  background-color: #edefee !important;
}

.font-container {
  font-family: "Roboto", sans-serif !important;
}

.btn-custom {
  display: inline-block;
  padding: 7px;
  background-color: #2a403d; /* Primary color */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Add box-shadow transition */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.btn-custom:disabled {
  background-color: #2a403d; /* Disabled color */
  opacity: 0.7; /* Reduce opacity for disabled state */
  cursor: not-allowed; /* Change cursor for disabled state */
  color: gray;
}

.custom-card-background {
  background-image: linear-gradient(
    to bottom,
    #2a403d,
    #1a2a28
  ); /* Gradient from #2a403d to #1a2a28 */
  text-align: center;
  color: white;
}

th {
  font-weight: normal;
  background-color: #2a403db2 !important;
  color: white !important;
  text-align: center !important;
}

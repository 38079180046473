.number-align-right {
  text-align: center;
  list-style-position: inside;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-7 {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.2;
}

.pl-6 {
  padding-left: 4rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.8rem;
  padding-left: 0.5rem;
  color: gray;
}

.stats-txt {
  font-size: 1.5rem;
  text-align: center;
}

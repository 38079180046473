.min-width {
  min-width: 150px;
}

.custom-card-background {
  background-color: #2a403d;
  text-align: center;
  color: white;
  opacity: 0.7;
}

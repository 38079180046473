fieldset {
  border: 1px solid lightgray !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  position: relative;
  margin-top: 1rem;
  border-radius: 10px;
}

.notes {
  color: gray;
}

.copyright {
  font-size: 0.75rem;
}

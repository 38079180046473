.mapContainer {
  margin-top: 25px;
}

.mapDiv {
  height: 250px;
}

.bigMap {
  height: 350px;
}

.map-container {
  height: 350px;
}

.multi-point-map {
  height: 350px;
}

.card-header {
  white-space: nowrap;
  background-color: #2a403d;
  text-align: center;
  color: white;
  opacity: 0.7;
}

.gasStation-header {
  background-color: #2a403d;
  text-align: center;
  color: white;
  opacity: 0.7;
  font-size: xx-large;
}

.gasStation-txt {
  font-size: 1.25rem;
  text-align: center;
}

.card-txt {
  font-size: 2rem;
  text-align: center;
}
